export default [
  {
    path: '/imported',
    name: 'dashboard.imported',
    component: () => import('../views/dashboard/imported/Index.vue'),
    meta: {auth: true}
  },
  {
    path: '/claims',
    name: 'dashboard.claims',
    component: () => import('../views/dashboard/claims/Index.vue'),
    meta: {auth: true}
  },
  {
    path: '/claims2',
    name: 'dashboard.claims2',
    component: () => import('../views/dashboard/claims/Index2.vue'),
    meta: {auth: true}
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: () => import('../views/dashboard/Index.vue'),
    meta: {auth: true}
  },
  {
    path: '/reparced',
    name: 'dashboard.reparsed',
    component: () => import('../views/dashboard/Reparced/Index'),
    meta: {auth: true}
  },
  {
    path: '/dashboard/profile',
    name: 'dashboard.profile',
    component: () => import('../views/dashboard/Profile.vue'),
    meta: {auth: true}
  },
  {
    path: '/dashboard/billing',
    name: 'dashboard.billing',
    component: () => import('../views/dashboard/billing/Index.vue'),
    meta: {auth: true}
  },
  {
    path: '/dashboard/billing/select-plan',
    name: 'dashboard.billing.select-plan',
    component: () => import('../views/dashboard/billing/SelectPlan.vue'),
    meta: {auth: true}
  },
  {
    path: '/dashboard/billing/checkout',
    name: 'dashboard.billing.checkout',
    component: () => import('../views/dashboard/billing/Checkout.vue'),
    meta: {auth: true}
  },
]
